<script>
import Layout from "../../layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Dashboard': 'Painel',
    },
    es: {
      'Dashboard': 'Painel',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      orders: [],
      sales: []
    };
  },
  methods: {
    getOrders() {
      api
        .get('franchise/graphs/orders')
        .then((response) => {
          if (response.data.status == 'success') {
            this.orders = response.data.list
          }
        })
    },
    getSales() {
      api
        .get('franchise/graphs/sales')
        .then((response) => {
          if (response.data.status == 'success') {
            this.sales = response.data.list
          }
        })
    }
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Dashboard') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            Seja bem-vindo a Franquia Home!
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
